import { useEffect, useState } from "react";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

const BASE_URL = "https://44.202.7.244:3000";

function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Fetch publishable key
    const fetchConfig = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/config`);
        const { publishableKey } = response.data;
        setStripePromise(loadStripe(publishableKey));
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();
  }, []);

  useEffect(() => {
    // Fetch client secret
    const createPaymentIntent = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/create-payment-intent`, {});
        const { clientSecret } = response.data;
        setClientSecret(clientSecret);
      } catch (error) {
        console.error("Error creating payment intent:", error);
      }
    };

    createPaymentIntent();
  }, []);

  return (
    <>
      <img
        src={process.env.PUBLIC_URL + "/img/brewcode-logo.png"}
        alt="Logo"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
       <div class="alert alert-primary d-flex align-items-center mt-4" role="alert">
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
  </svg>
   <div>
     this payment app does not process actual transactions or Deduct any amount
   </div>
  </div>
   <div class="alert alert-warning" role="alert">
     Note: Use the card number 4242 4242 4242 4242, expiration month 04/24, and CVC 424, country Ireland for testing purposes.
   </div>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}

export default Payment;
