import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';

function Completion(props) {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <h1 className="mb-4">Thank you! 🎉</h1>
          <p className="lead">Your payment has been successfully processed.</p>
          <p>We appreciate your business and look forward to serving you again.</p>
        </Col>
      </Row>
    </Container>
  );
}

export default Completion;